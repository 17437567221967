import "./assets/css/App.css";
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes as Switch } from "react-router-dom";

import Provider from "./helpers/hooks/useGlobalContext";
const Home = React.lazy(() => import('./pages/Home'));
const Token = React.lazy(() => import('./pages/Token'));
const Document = React.lazy(() => import('./pages/Document'));
const Team = React.lazy(() => import('./pages/Team'));
const Privacy = React.lazy(() => import('./pages/Privacy'));
const DisclaimerPage = React.lazy(() => import('./pages/DisclaimerPage'));

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Provider>
        <Router>
          <Switch>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/token" element={<Token />} />
            <Route exact path="/documents" element={<Document />} />
            <Route exact path="/team" element={<Team />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/disclaimer" element={<DisclaimerPage />} />
            {/* <Route path="*" component={NotFound} /> */}
          </Switch>
        </Router>
      </Provider>
    </Suspense>
  );
}

export default App;
